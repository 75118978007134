import { Button, Card, Container, createStyles, Grid, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import LocationIcon from 'components/Icons/LocationIcon';
import { coralColor, secondaryColor, whiteColor } from 'library/Colors';
import { basePath, NossasUnidadesHashName } from 'library/Routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { getNoCache } from 'utils/NoCacheUtils';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        display: "flex",
        width: '100%',
      },
      container: {
        position: 'relative',
        width: '100%',
        paddingBottom: theme.spacing(6),
      },
      bg: {
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translateX(-50%)',
        backgroundImage: `url(${basePath}assets/images/porto.png?nocache=${getNoCache()})`,
        backgroundPosition: 'center',
        width: '100%',
        maxHeight: '305px',
        minHeight: '305px',
      },
      title: {
        color: whiteColor,
        fontSize: '33px',
        fontWeight: 600,
        textAlign: 'center',
        marginTop: theme.spacing(6),
      },
      blocksContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      blocks: {
        paddingTop: theme.spacing(5),
        maxWidth: '1000px',
      },
      block: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
      },
      card: {
        borderRadius: '24px',
        padding: theme.spacing(3, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        borderColor: '#DA487B',
        borderWidth: '1px',
        borderStyle: 'solid',
        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
      },
      blockTitle: {
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'center',
        color: '#DA487B',
      },
      blockText: {
        marginTop: theme.spacing(1),
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'center',
      },
      blockSpan: {
        marginTop: theme.spacing(1),
        fontSize: '11px',
        fontWeight: 500,
        textAlign: 'center',
      },
      button: {
        backgroundImage: `linear-gradient(226.03deg, ${coralColor} 4.7%, ${secondaryColor} 96.58%)`,
        borderRadius: '8px',
        fontSize: '14px',
        color: whiteColor,
        textTransform: 'initial',
        textDecoration: 'none !important',
        lineHeight: '29px',
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 1),
        boxShadow: 'none !important',
      },
      icon: {
        fontSize: '60px',
      }
    }),
);

const UnidadesAtendimento = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Element
      name={NossasUnidadesHashName}
      className={classes.root}
    >
      <div className={classes.bg}></div>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography component="h5" className={classes.title}>
                  {t(`Home.UnidadesAtendimento.title`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.blocksContainer}>
            <Grid container spacing={4} className={classes.blocks} justify="center">
              <Grid item xs={12} sm={4} className={classes.block}>
                <Card className={classes.card}>
                  <LocationIcon className={classes.icon} />
                  <Typography component="h5" className={classes.blockTitle}>
                    {t(`Home.UnidadesAtendimento.portoAlegre.city`)}
                  </Typography>
                  <Typography component="h5" className={classes.blockText} color="primary">
                    {t(`Home.UnidadesAtendimento.portoAlegre.address`)}
                  </Typography>
                  <Typography component="h5" className={classes.blockSpan} color="primary">
                    <span dangerouslySetInnerHTML={{ __html: t(`Home.UnidadesAtendimento.portoAlegre.when`) }} />
                  </Typography>
                  <div style={{flex: 1}}></div>
                  <Link
                    // variant="contained"
                    className={classes.button}
                    component={Button}
                    href="https://goo.gl/maps/DrTQxXZLL867SCiT8"
                    target="_blank"
                  >
                    {t(`Home.UnidadesAtendimento.portoAlegre.buttonLabel`)}
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.block}>
                <Card className={classes.card}>
                  <LocationIcon className={classes.icon} />
                  <Typography component="h5" className={classes.blockTitle}>
                    {t(`Home.UnidadesAtendimento.mostardeiro.city`)}
                  </Typography>
                  <Typography component="h5" className={classes.blockText} color="primary">
                    {t(`Home.UnidadesAtendimento.mostardeiro.address`)}
                  </Typography>
                  <Typography component="h5" className={classes.blockSpan} color="primary">
                    <span dangerouslySetInnerHTML={{ __html: t(`Home.UnidadesAtendimento.mostardeiro.when`) }} />
                  </Typography>
                  <div style={{flex: 1}}></div>
                  <Link
                    // variant="contained"
                    className={classes.button}
                    component={Button}
                    href="https://www.google.com/maps/place/R.+Mostardeiro,+265+-+Moinhos+de+Vento,+Porto+Alegre+-+RS,+90620-110/@-30.0290436,-51.2066946,17z/data=!3m1!4b1!4m5!3m4!1s0x951979b41486989f:0x8db5c3ca45db3bf7!8m2!3d-30.0290483!4d-51.2045059"
                    target="_blank"
                  >
                    {t(`Home.UnidadesAtendimento.mostardeiro.buttonLabel`)}
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.block}>
                <Card className={classes.card}>
                  <LocationIcon className={classes.icon} />
                  <Typography component="h5" className={classes.blockTitle}>
                    {t(`Home.UnidadesAtendimento.xangrila.city`)}
                  </Typography>
                  <Typography component="h5" className={classes.blockText} color="primary">
                    {t(`Home.UnidadesAtendimento.xangrila.address`)}
                  </Typography>
                  <Typography component="h5" className={classes.blockSpan} color="primary">
                    <span dangerouslySetInnerHTML={{ __html: t(`Home.UnidadesAtendimento.xangrila.when`) }} />
                  </Typography>
                  <div style={{flex: 1}}></div>
                  <Link
                    // variant="contained"
                    className={classes.button}
                    component={Button}
                    href="https://www.google.com/maps/place/Hospital+LifePlus+Litoral+Norte/@-29.8009484,-50.0548262,21z/data=!4m8!1m2!2m1!1sRua+Ant%C3%B4nio+Jo%C3%A3o+de+Medeiros+xangri-l%C3%A1!3m4!1s0x95187926b9335d73:0x627eb535079c970d!8m2!3d-29.8009484!4d-50.0545611"
                    target="_blank"
                  >
                    {t(`Home.UnidadesAtendimento.xangrila.buttonLabel`)}
                  </Link>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Element>
  )
}

export default UnidadesAtendimento;